
import {
  defineComponent,
  onActivated,
  ref,
  watch,
  nextTick,
  computed,
} from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
//  utils
import api from "@/api/axios";
//ts
import { productMap} from "@/hooks/useProduct";

export default defineComponent({
  name: "GB31658Product",
  components: {
    banner,
    list,
    productCard,
  },
  setup() {
    const list = ref(null);
    const productList = ref(null);
    const route = useRoute();
    const firstClass = ref(route.query.firstClass || "0");

    const producturl = computed(() => {
      return `/M/Product/getLatestGBList?firstClass=${firstClass.value}`;
    });
    const refreshList = ref(true);
    const productparams = {
    };
    api.get("/M/Product").then((res): void => {
      list.value = res.data.obj.listimg;
    });
    watch(
            [
              () => {
                return route.query;
              },
              () => {
                return route.name;
              },
            ],
            (to, from) => {

              // 从分类跳转 或者是从首页的 标准物质跳转 会从新请求数据渲染 ，
              // 侧边栏的链接跳转直接复用之前的数据
              // console.log("from[1] :",from[1])
              if (from[1] == "Login") {
                refreshList.value = false;
                nextTick(() => {
                  refreshList.value = true;
                });
              } else if (from[1] == "Index") {
                refreshList.value = false;
                nextTick(() => {
                  refreshList.value = true;
                });
              }
            }
    );
    if(localStorage.getItem('WX')){
      setTimeout(function () {
        location.reload();
      },1000)
    }
    localStorage.removeItem('WX')
    //  切换列表菜单

    return {
      list,
      productList,
      producturl,
      productMap,
      productparams,
      refreshList,
    };
  },
});
