<template>
  <div>
    <banner :list="list"></banner>

    <div v-if="refreshList">
      <list
        :url="producturl"
        :params="productparams"
        :filterFunc="productMap"
        :show="true"
      >
        <template v-slot:default="data">
          <product-card
            :list="data"
            :totalResult="totalResult"
            name="productdetail"
            tid="0"
          ></product-card>
        </template>
      </list>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  onActivated,
  ref,
  watch,
  nextTick,
  computed,
} from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
//  utils
import api from "@/api/axios";
//ts
import { productMap} from "@/hooks/useProduct";

export default defineComponent({
  name: "GB31658Product",
  components: {
    banner,
    list,
    productCard,
  },
  setup() {
    const list = ref(null);
    const productList = ref(null);
    const route = useRoute();
    const firstClass = ref(route.query.firstClass || "0");

    const producturl = computed(() => {
      return `/M/Product/getLatestGBList?firstClass=${firstClass.value}`;
    });
    const refreshList = ref(true);
    const productparams = {
    };
    api.get("/M/Product").then((res): void => {
      list.value = res.data.obj.listimg;
    });
    watch(
            [
              () => {
                return route.query;
              },
              () => {
                return route.name;
              },
            ],
            (to, from) => {

              // 从分类跳转 或者是从首页的 标准物质跳转 会从新请求数据渲染 ，
              // 侧边栏的链接跳转直接复用之前的数据
              // console.log("from[1] :",from[1])
              if (from[1] == "Login") {
                refreshList.value = false;
                nextTick(() => {
                  refreshList.value = true;
                });
              } else if (from[1] == "Index") {
                refreshList.value = false;
                nextTick(() => {
                  refreshList.value = true;
                });
              }
            }
    );
    if(localStorage.getItem('WX')){
      setTimeout(function () {
        location.reload();
      },1000)
    }
    localStorage.removeItem('WX')
    //  切换列表菜单

    return {
      list,
      productList,
      producturl,
      productMap,
      productparams,
      refreshList,
    };
  },
});
</script>

<style lang="scss" scoped>
  //  list  nav
  .menu-nav-wapper {
    overflow: hidden;
    height: 45px;
    line-height: 45px;
    border-bottom: 1.012px solid #f4f4f4;

    .menu-nav {
      overflow: hidden;
      height: 45px;
      width: 100%;
      line-height: 45px;
      border-bottom: 1px solid #f4f4f4;
      top: 0;
      z-index: 20;
      background-color: #fff;
    }
    li {
      float: left;
      width: 33.3%;
      text-align: center;
      a {
        display: block;
        text-align: center;
        line-height: 45px;
        width: 100%;
        font-size: 16px;
        color: #666;
      }
    }

    .active a {
      color: #df0024;
    }
  }
</style>
